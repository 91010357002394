<template>
  <div class="packet-body">
    <div class="help-packet">
      <div class="red-packet">
        <div class="packet-info">
          <img :src="userInfo.wxAvatar || defaultAvatar"/>
          <p class="support">点开红包，帮我助力</p>
          <p class="packet-text">恭喜发财，大吉大利</p>
        </div>
        <div class="open-icon">
          <img src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_coins.png" @click="operate">
        </div>
      </div>
    </div>
    <div class="dialog-step-two">
      <van-dialog v-model="showStepTwo" :showConfirmButton="false" theme="default">
        <div class="step-two-top">
<!--          <van-icon class="back" name="arrow-left" size="16" color="#ffffff" @click="handleBack(2)"/>-->
<!--          <p>年终现金红包</p>-->
          <div class="step-two-avatar">
            <img :src="userInfo.wxAvatar || defaultAvatar"/>
            <p>谢谢你帮我助力！</p>
          </div>
          <p class="share">分你一个红包</p>
          <p class="money">¥<span>{{ taskAmount }}</span></p>
          <img src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_wx_logo.png">
        </div>
        <div class="step-two-button">
          <van-button @click="handleShowStepThree">点击领取</van-button>
        </div>
      </van-dialog>
      <van-overlay :show="showStepThree" z-index="3000" style="background-color: rgba(0,0,0,.9);">
<!--        <van-icon class="back-three" name="arrow-left" size="16" color="#ffffff" @click="handleBack(3)"/>-->
        <div class="step-three">
          <div class="step-three-content">
            <p>已存入我的红包</p>
            <p class="money">¥<span>{{threeAmount}}</span></p>
            <p>可提现到微信零钱</p>
          </div>
          <van-button @click="handleJumpTaskIndex">立即领取</van-button>
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Dialog, Icon, Button, Overlay } from 'vant'
import baseUrl from '@/utils/baseUrl'

const api = require('../../../utils/api').api

Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Overlay)

export default {
  name: 'helpPacket',
  data () {
    return {
      token: '',
      version: '',
      channel: '',
      taskAmount: '',
      threeAmount: '',
      taskId: '',
      showStepTwo: false,
      showStepThree: false,
      defaultAvatar: 'https://cdn.cailu88.com/jingxuanshi/material/icon/avatar_3x.jpg',
      // 邀请码
      inviteCode: '',
      // 用户id
      userId: '',
      userInfo: {}
    }
  },
  props: {
    source: String
  },
  created () {
    if (this.$store.state.isBack == true) {
      this.$h5AppLink('navIndex', {})
    } else {
      this.token = this.$route.query.token
      this.version = this.$route.query.version
      this.channel = this.$route.query.channel
      this.taskId = this.$route.query.taskId
      this.inviteCode = this.$route.query.inviteCode
      this.$store.commit('backToChoose',{isBack:true})
      this.initSupportInfo()
    }
  },
  methods: {
    //红包助力信息
    initSupportInfo () {
      this.$axios(api.taskSupport, {
        params: {
          taskId: this.taskId
        }
      }).then(res => {
        this.userInfo = res.data.data
      }).catch(err => {
        console.info(err)
        this.isLogin()
      })
    },
    operate () {
      if (this.source == 'wx') {
        this.$emit('openApp')
      } else {
        this.handleTaskBoost()
        this.showStepTwo = true
        this.taskAmount = (Math.random() * 2.5 + 0.5).toFixed(2)
        if (this.taskAmount > 3) {
          this.taskAmount = 3
        }
      }
    },
    // 助力信息
    handleTaskBoost () {
      this.$axios(api.taskBoost, {
        params: {
          taskId: this.taskId
        }
      }).then(res => {
      }).catch(err => {
        console.info(err)
      })
    },
    // 助力红包返回
    // handleBack (value) {
    //   if (value == 2) {
    //     this.showStepTwo = false
    //   } else {
    //     this.showStepThree = false
    //   }
    // },
    // 助力红包3显示
    handleShowStepThree () {
      this.$axios(api.taskOpen, {
        params: {}
      }).then(res => {
        this.threeAmount = res.data.data.amount
        this.taskId = res.data.data.taskId
        this.showStepThree = true
      }).catch(err => {
        console.info(err)
        this.isLogin()
      })
    },
    // 跳转红包主页
    handleJumpTaskIndex () {
      this.$router.push({ name: 'pullNew', query:{token:this.token,version:this.version,channel:this.channel,taskId:this.taskId} })
    },
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/packet/choose?taskId=${this.taskId}`
      }
      this.$h5AppLink(event, param)
    }
  }
}
</script>
<style lang="less" scoped>

.packet-body {
  .help-packet {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    opacity: 0.9;
    min-height: 100vh;

    .red-packet {
      position: relative;
      width: 300px;
      height: 440px;
      background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_wx.png");
      background-size: cover;

      .packet-info {
        text-align: center;

        & > img {
          margin-top: 24px;
          width: 60px;
          height: 60px;
          border-radius: 30px;
        }

        .support {
          margin-top: 14px;
          height: 21px;
          font-size: 15px;
          font-weight: 500;
          color: #FFDEAE;
          line-height: 21px;
        }

        .packet-text {
          margin-top: 34px;
          font-size: 22px;
          font-weight: 800;
          color: #FFDEAE;
          line-height: 30px;
        }
      }

      .open-icon {
        position: absolute;
        bottom: 68px;
        left: 108px;

        & > img {
          width: 88px;
        }
      }
    }
  }

  .dialog-step-two {
    .van-dialog {
      width: 376px;
      height: 100vh;
      background: #F35742;
      color: #FFFFFF;
      top: 50%;
      border-radius: 0;

      .step-two-top {
        position: relative;
        text-align: center;
        width: 100%;
        height: 360px;
        background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_1.png");
        background-size: cover;

        & > p {
          font-size: 18px;
          font-weight: bold;
          line-height: 25px;
        }

        .back {
          position: absolute;
          top: 3px;
          left: 2px;
          width: 9px;
          height: 16px;
        }

        .step-two-avatar {
          padding-top: 52px;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 30px;
            height: 30px;
            border-radius: 30px;
          }

          p {
            margin-left: 10px;
            font-size: 15px;
            font-weight: 500;
            line-height: 21px;
          }
        }

        .share {
          margin-top: 28px;
          font-size: 22px;
          line-height: 30px;
          font-weight: 800;

        }

        .money {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-top: 10px;
          font-size: 30px;
          font-weight: 800;
          color: #FDDD6D;
          line-height: 58px;

          span {
            margin-left: 5px;
            font-size: 60px;
            line-height: 84px;
          }
        }

        & > img {
          margin-top: 20px;
          width: 118px;
          height: 30px;
        }
      }

      .step-two-button {
        text-align: center;
        margin-top: 42px;

        .van-button {
          width: 310px;
          height: 60px;
          background: Linear-gradient(#FFE4B5, #FDDD6D 40%);
          box-shadow: 0px 3px 4px 0px rgba(217, 169, 0, 0.5);
          border-radius: 30px;
          border: none;
          font-size: 22px;
          font-weight: 800;
          color: #E02D20;
        }
      }
    }
  }

  .back-three {
    margin-top: 18px;
    width: 9px;
    height: 16px;
  }

  .step-three {
    position: relative;
    margin-top: 40px;
    width: 100%;
    height: 370px;
    background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_3.png");
    background-size: cover;
    text-align: center;

    .step-three-content {
      position: relative;
      top: 84px;
      left: 28px;
      width: 318px;
      height: 200px;
      background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/pull_packet_bg_2.png");
      background-size: cover;

      & > p:first-child {
        position: relative;
        top: 30px;
        font-size: 18px;
        font-weight: 800;
        color: #E02D20;
        line-height: 25px;
      }

      & > p:last-child {
        margin-top: 10px;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

      .money {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 30px;
        font-size: 30px;
        font-weight: 800;
        color: #E02D20;
        line-height: 58px;

        span {
          margin-left: 7px;
          font-size: 60px;
          line-height: 84px;
        }
      }
    }

    .van-button {
      position: absolute;
      bottom: 0;
      left: 35px;
      width: 310px;
      height: 60px;
      background: Linear-gradient(#FFE4B5, #FDDD6D 40%);
      box-shadow: 0px 3px 4px 0px rgba(217, 169, 0, 0.5);
      border-radius: 30px;
      border: none;
      font-size: 22px;
      font-weight: 800;
      color: #E02D20;
    }
  }
}
</style>
